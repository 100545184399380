<template>
  <v-container style="margin-top: -100px">
    <!-- <v-row align="center" class="row--35"> -->
    <v-row class="row--35">
      <!-- <v-col class="d-md-none" lg="3" md="3" cols="12">
        <div style="margin-top: 40px; margin-bottom: 120px;">
          <slot name="thum-img"></slot>
        </div>
      </v-col> -->
      <v-col lg="9" md="9" cols="12">
        <div class="about-inner inner">
          <v-row class="mt--80 ml--0">
            <div class="d-sm-flex flex-column">
              <div class="p-6">
                <h3 class="heading-title colorSilver mt_sm--0 ml--15">
                  CHECK UP
                </h3>
              </div>
              <div class="p-6 pb-5" style="margin-top: -14px">
                <p
                  style="font-size: 20px; color: #333333"
                  class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                >
                  (Υγεία, Μetropolitan Hospital, Μητέρα, <br />
                  Metropolitan General, Λητώ, Creta Interclinic)
                </p>
              </div>
            </div>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                  cols="12"
                  class="withoutBorder"
                  style="border-right: 1px dotted"
                >
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="color: red; font-size: 20px"
                  >
                    Check up για τη γυναίκα
                  </h3>

                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Γυναικολογικός έλεγχος:
                    <span class="colorGold"> 25 €</span>
                  </h3>
                  <ul>
                    <li>
                      Εξετάσεις Αίματος: Γενική Αίματος, TSH, Σίδηρος, Φερριτίνη
                    </li>
                    <li>Γενική Ούρων</li>
                    <li>Τεστ ΠΑΠ</li>
                    <li>Βασική Γυναικολογική εξέταση</li>
                    <li>Κλινική εξέταση Μαστών</li>
                  </ul>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Ενδοκολπικό Υπερηχογράφημα
                    <span class="colorGold"> • δωρεάν</span>
                  </h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Υπέρηχος μαστού
                    <span class="colorGold"> • δωρεάν</span>
                  </h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Μαστογραφία
                    <span class="colorGold"> • δωρεάν</span>
                  </h3>
                  <p>(ανά έτος)</p>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--0"
                    style="font-size: 20px"
                  >
                    Τομοσύνθεση: <span class="colorGold"> 40 €</span>
                  </h3>
                  <p
                    style="font-size: 13px; line-height: 1.5; color: #777978"
                    class="mb_sm--20 mb_md--20 mt--5"
                  >
                    (Μetropolitan Hospital, & Metropolitan General)
                  </p>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Έλεγχος οστεοπόρωσης:
                    <span class="colorGold"> 50 €</span>
                  </h3>
                  <ul>
                    <h3
                      class="heading-title mt--0 mb--10"
                      style="font-size: 20px; margin-left: -15px"
                    ></h3>
                    <li>
                      Αιματολογικός έλεγχος (Ασβέστιο, Αλβουμίνη, 25
                      hydroxyvitamin D)
                    </li>
                    <li>
                      Μέτρηση οστικής πυκνότητας ισχίου και οσφυϊκής μοίρας
                    </li>
                    <li>Αξιολόγηση από Ρευματολόγο</li>
                  </ul>
                </v-col>
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                  cols="12"
                  class="withoutBorder"
                  style="border-right: 1px dotted"
                >
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="color: red; font-size: 20px"
                  >
                    Check up για τον άνδρα
                  </h3>
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="font-size: 20px"
                  ></h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Πρόγραμμα ελέγχου προστάτη:
                    <span class="colorGold"> 25 €</span>
                  </h3>
                  <ul>
                    <li>P.S.A.</li>
                    <li>FREE P.S.A.</li>
                    <li>Κλινική εξέταση προστάτη</li>
                  </ul>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Τριχόπτωση
                  </h3>
                  <ul>
                    <li>
                      Συνεδρία με δερματολόγο και τριχοριζόγραμμα (Metropolitan
                      Hospital & Υγεία) <span class="colorGold"> • δωρεάν</span>
                    </li>
                  </ul>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="color: red; font-size: 20px"
                  >
                    Check up για το παιδί
                    <span
                      style="font-size: 13px; line-height: 1.5; color: #777978"
                      class="mb_sm--20 mb_md--20 mt--5"
                    >
                      (Μetropolitan Hospital, Mητέρα)
                    </span>
                  </h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  ></h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Προληπτικός έλεγχος υγείας:
                    <span class="colorGold"> 35 €</span>
                  </h3>
                  <ul>
                    <li>Γενική αίματος</li>
                    <li>Σάκχαρο</li>
                    <li>Κρεατινίνη</li>
                    <li>Oυρία</li>
                    <li>Τριγλυκερίδια</li>
                    <li>FT4</li>
                    <li>HDL χοληστερίνης</li>
                    <li>LDL χοληστερίνης</li>
                    <li>Χοληστερίνη αίματος</li>
                    <li>Φερριτίνη</li>
                    <li>Σίδηρος</li>
                    <li>Τ.Κ.Ε.</li>
                    <li>Παιδιατρική εξέταση</li>
                  </ul>
                  <v-row class="mt--20">
                    <v-col lg="12" md="12" sm="12" cols="12">
                      <span class="freeTicketText"> ΔΩΡΕΑΝ </span>
                    </v-col>
                  </v-row>
                  <!-- <span class="freeTicketRight"> ΔΩΡΕΑΝ </span> -->
                  <h3
                    class="heading-title heading-title-new mb--0 mt--10"
                    style="font-size: 20px"
                  >
                    ✓ Εξέταση σπονδυλικής στήλης για παιδιά
                  </h3>
                  <!-- <h3
                    class="heading-title heading-title-new mb--0 mt--10"
                    style="font-size: 20px"
                  >
                    ✓ Αιματολογικός έλεγχος ετησίως σε όλα τα θεραπευτήρια και
                    τα διαγνωστικά κέντρα HealthSpot:
                  </h3>
                  <p>
                    (Γενική αίματος, Σάκχαρο, Ουρία, Κρεατινίνη, Χοληστερόλη,
                    HDL, LDL, Τριγλυκερίδια, SGOT, SGPT, TSH, Γενική ούρων)
                  </p> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- <span class="freeTicket mt--50"> ΔΩΡΕΑΝ </span> -->
          <v-row class="mt--20">
            <v-col lg="12" md="12" sm="12" cols="12">
              <span class="freeTicketText"> ΔΩΡΕΑΝ </span>
            </v-col>
          </v-row>
          <v-row class="mt--10">
            <v-col lg="8" md="8" sm="12" cols="12">
              <ul>
                <li>
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    Ένας αιματολογικός έλεγχος ανά έτος σε όποιο Θεραπευτήριο
                    του Ομίλου επιθυμείτε.
                  </h3>
                  <div class="pb--20" style="margin-top:-10px">
                    <p style="font-size: 16px">
                      Περιλαμβάνει: Γενική αίματος, Σάκχαρο, Ουρία, Κρεατινίνη,
                      Χοληστερόλη, HDL, LDL, Τριγλυκερίδια, SGOT, SGPT, TSH,
                      Γενική ούρων.
                    </p>
                  </div>
                </li>
                <li>
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    Ένας βασικός αιματολογικός έλεγχος ανά έτος στα Διαγνωστικά
                    Κέντρα HealthSpot.
                  </h3>
                  <div class="pb--20" style="margin-top:-10px">
                    <p style="font-size: 16px">
                      Περιλαμβάνει: Γενική αίματος, Γενική Ούρων, Σάκχαρο,
                      Ουρία, Χοληστερίνη.
                    </p>
                  </div>
                </li>
              </ul>
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--20 .d-block .d-sm-none">
            <v-img
              height="85"
              max-width="110"
              style="margin-left: 12px"
              class="mb--10"
              src="../../assets/images/pronomia/homecare_logo.svg"
            ></v-img>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex flex-column">
                <div class="p-6">
                  <h3 class="heading-title colorBlue">HomeCare</h3>
                </div>
                <div class="p-6 pb-5" style="margin-top: -14px">
                  <p
                    style="font-size: 20px; color: #333333"
                    class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                  >
                    Κατ’ οίκον Υπηρεσίες Υγείας
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>

          <h3 class="heading-title heading-title-new" style="font-size: 20px">
          Εάν έχετε νοσηλευτεί σε ένα από τα θεραπευτήρια του HHG έχετε έκπτωση
          10% στην υπηρεσία HomeCare
        </h3>
          <v-row>
            <v-col>
              <v-row>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <v-row class="mt--20">
                    <v-col lg="12" md="12" sm="12" cols="12">
                      <h3
                        class="heading-title heading-title-new"
                        style="font-size: 20px"
                      >
                        <span class="freeTicketText"> ΔΩΡΕΑΝ </span> οι 2 πρώτες
                        επισκέψεις και μετά 40% Έκπτωση σε
                      </h3>
                    </v-col>
                  </v-row>

                  <div style="margin-top:-15px" class="pb-5">
                    <ul>
                      <li>Αιμοληψία & μεταφορά δείγματος</li>
                      <li>Συλλογή ούρων ή άλλων βιολογικών υγρών</li>
                      <li>
                        Παρακολούθηση του ασθενή & καταγραφή των ζωτικών σημείων
                      </li>
                    </ul>
                  </div>
                </v-col>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    40% Έκπτωση σε
                  </h3>
                  <div style="margin-top:-15px">
                    <ul>
                      <li>Ηλεκτροκαρδιογράφημα κατ’ οίκον</li>
                    </ul>
                  </div>
                </v-col>

                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    30% Έκπτωση σε
                  </h3>
                  <div style="margin-top:-15px">
                    <ul>
                      <li>Χορήγηση φαρμάκου με υποδόρια ένεση</li>
                      <li>Χορήγηση φαρμάκου με ενδομυική ένεση</li>
                      <li>Χορήγηση εισπνεόμενων φαρμάκων με δοσομετρητή</li>
                      <li>Χορήγηση οξυγόνου με μάσκα</li>
                      <li>Ενδοφλέβια χορήγηση ορού</li>
                      <li>Ενδοφλέβια χορήγηση φαρμάκων</li>
                      <li>Αναρρόφηση τραχειοβρογχικών εκκρίσεων</li>
                      <li>Υποκλυσμός</li>
                      <li>Παρεντερική σίτιση</li>
                      <li>
                        Τοποθέτηση ουροκαθετήρα & πλύσεις ουροδόχου κύστεως
                      </li>
                      <li>Ατομική υγιεινή</li>
                      <li>
                        Φροντίδα στομίων & εκπαίδευση (γαστροστομία, κολοστομία,
                        κά)
                      </li>
                      <li>Αλλαγή & περιποίηση τραύματος</li>
                      <li>Πρόληψη & φροντίδα κατάκλισης</li>
                      <li>Περιποίηση εγκαύματος</li>
                      <li>Εκπαίδευση ασθενούς, κατά περίπτωση</li>
                      <li>Holter ρυθμού (24ωρη καταγραφή ηκγ)</li>
                      <li>Holter πίεσης (24ωρη καταγραφή αρτηριακής πίεσης)</li>
                    </ul>
                  </div>
                </v-col>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    15% Έκπτωση σε
                  </h3>
                  <div style="margin-top:-15px">
                    <ul>
                      <li>Φυσικοθεραπευτική συνεδρία κατ’οικον</li>
                      <li>Ιατρική επίσκεψη κατ’ οίκον</li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="d-block d-sm-none mt--50 mt_sm--10">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3
                class="heading-title heading-title-new mt--10 mb--10"
                style="font-size: 20px"
              >
                Καμπάνιες
              </h3>
              <p style="font-size: 16px">
                Μέσα απο την εφαρμογή θα ενημερώνεστε άμεσα για τις καμπάνιες
                μας για την πρόληψη υγείας σε εξαιρετικά προνομιακές τιμές.
              </p>
            </v-col>
          </v-row>
          <!-- <v-row class="d-block d-sm-none mt--50 mt_sm--10">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3
                class="heading-title heading-title-new mt--10 mb--10"
                style="font-size: 20px"
              >
                Πάρκινγκ
              </h3>
              <p style="font-size: 16px">
                Δωρεάν πάρκινγκ για τους κατόχους της Health_e Bonus Card Gold
              </p>
            </v-col>
          </v-row> -->
        </div>
      </v-col>
      <v-col class="mt--100 d-none d-md-block" lg="3" md="3" cols="12">
        <div>
          <slot name="thum-img"></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.colorGold {
  color: #d4af37;
  font-weight: initial;
  font-weight: bold;
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}
th {
  font-size: 14px !important;
}

.freeTicket {
  background-color: rgb(212, 147, 34);
  position: absolute;
  margin-bottom: 20px;
  left: -23%;
  right: 71%;
  text-align: right;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-top-right-radius: 25px 22px;
  border-bottom-right-radius: 25px 22px;
  padding-right: 20px;
}
.freeTicketText {
  color: red;
  font-size: 22px;
  font-family: "PFBagueSansPro-Bold";
  font-weight: bold;
}

.freeTicketRight {
  background-color: rgb(212, 147, 34);
  position: absolute;
  margin-bottom: 20px;
  left: 0%;
  right: 58%;
  text-align: right;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-top-right-radius: 25px 22px;
  border-bottom-right-radius: 25px 22px;
  padding-right: 20px;
}

h3 {
  font-family: "PFBagueSansPro-Bold";
}

.v-application p,
body p {
  line-height: 1.5;
}
.v-application ul,
.v-application ol,
body ul,
body ol {
  line-height: 1.5;
}
@media only screen and (max-width: 600px) {
  .heading-title-new {
    font-size: 16px !important;
  }
  .smallerImg {
    padding: 10px 10px 10px 0px;
    max-width: 50%;
  }
  .withoutBorder {
    border-right: 0px dotted !important;
  }
}
</style>
