<template>
  <div>
    <BrandChooseWindow />
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo-all-dark.png" />
    </Header>
    <!-- Start Breadcrump Area  -->
    <div class="bg_image bg_image--16 d-none d-md-block">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100"></div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <SliderPrivileges />
    <div id="white" v-if="$store.state.selectedTab === 'white'">
      <div class="about-area rn-section-gap bg_color--1">
        <div class="about-wrapper">
          <White>
            <img
              slot="thum-img"
              class="w-20"
              src="../assets/images/about/HealthSpot.png"
              alt="About Images"
            />
          </White>
        </div>
      </div>
    </div>

    <div id="blue" v-if="$store.state.selectedTab === 'blue'">
      <div class="about-area rn-section-gap bg_color--1">
        <div class="about-wrapper">
          <BlueFirst>
            <img
              slot="thum-img"
              class="w-20"
              src="../assets/images/about/HealthSpot.png"
              alt="About Images"
            />
          </BlueFirst>
        </div>
      </div>

      <div class="about-area bg_color--1">
        <div class="about-wrapper">
          <BlueSecond>
            <img
              style="position: absolute; right: -33%"
              slot="thum-img"
              class="imageWidth"
              src="../assets/images/pronomia/checkup.png"
              alt="About Images"
            />
          </BlueSecond>
        </div>
      </div>

      <div class="about-area bg_color--1">
        <div class="about-wrapper">
          <BlueThird>
            <img
              style="position: absolute; right: -27%; top: -30%"
              slot="thum-img"
              class="imageWidth"
              src="../assets/images/pronomia/checkup.png"
              alt="About Images"
            />
          </BlueThird>
        </div>
      </div>
    </div>

    <div id="silver" v-if="$store.state.selectedTab === 'silver'">
      <div class="about-area rn-section-gap bg_color--1">
        <div class="about-wrapper">
          <SilverFirst>
            <img
              slot="thum-img"
              class="w-20"
              src="../assets/images/about/HealthSpot.png"
              alt="About Images"
            />
          </SilverFirst>
        </div>
      </div>

      <div class="about-area bg_color--1">
        <div class="about-wrapper">
          <SilverSecond>
            <img
              style="position: absolute; right: -33%"
              slot="thum-img"
              class="imageWidth"
              src="../assets/images/pronomia/campaingns.png"
              alt="About Images"
            />
          </SilverSecond>
        </div>
      </div>
    </div>

    <div id="gold" v-if="$store.state.selectedTab === 'gold'">
      <div class="about-area rn-section-gap bg_color--1">
        <div class="about-wrapper">
          <GoldFirst>
            <img
              slot="thum-img"
              class="w-20"
              src="../assets/images/about/HealthSpot.png"
              alt="About Images"
            />
          </GoldFirst>
        </div>
      </div>

      <div class="about-area bg_color--1">
        <div class="about-wrapper">
          <GoldSecond>
            <img
              style="position: absolute; right: -60%"
              slot="thum-img"
              class="imageWidth"
              src="../assets/images/pronomia/campaingns.png"
              alt="About Images"
            />
          </GoldSecond>
        </div>
      </div>

      <div class="about-area rn-section-gap bg_color--1">
        <div class="about-wrapper">
          <GoldThird>
            <!-- <img
              class="d-none d-sm-block"
              style="position: absolute; right: -33%"
              slot="thum-img"
              src="../assets/images/pronomia/parking.png"
              alt="About Images"
            /> -->
            <!-- <img
              style="position: absolute; right: -33%"
              slot="thum-img"
              class="imageWidth"
              src="../assets/images/pronomia/parking.png"
              alt="About Images"
            /> -->
          </GoldThird>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/header/HeaderOnePage";
import About from "../components/about/About";
import SliderPrivileges from "../components/slider/SliderPrivileges";
import White from "../components/about/White";
import Gold from "../components/about/Gold";
import GoldFirst from "../components/about/GoldFirst";
import SilverFirst from "../components/about/SilverFirst";
import BlueFirst from "../components/about/BlueFirst";
import SilverSecond from "../components/about/SilverSecond";
import BlueSecond from "../components/about/BlueSecond";
import BlueThird from "../components/about/BlueThird";
import GoldSecond from "../components/about/GoldSecond";
import GoldThird from "../components/about/GoldThird";
import CounterOne from "../components/counter/CounterOne";
import TeamThree from "../components/team/TeamThree";
import Testimonial from "../components/testimonial/Testimonial";
import BrandChooseWindow from "../components/brand/BrandChooseWindow";
import Footer from "../components/footer/FooterTwo";
export default {
  components: {
    Header,
    About,
    White,
    Gold,
    SliderPrivileges,
    GoldFirst,
    SilverSecond,
    BlueFirst,
    BlueSecond,
    BlueThird,
    SilverFirst,
    GoldSecond,
    GoldThird,
    CounterOne,
    TeamThree,
    Testimonial,
    BrandChooseWindow,
    Footer,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false,
        },
        {
          text: "About",
          to: "",
          disabled: true,
        },
      ],
    };
  },
  // props: ['privilegeSelected'],
  props: {
    privilegeSelected: {
      type: String,
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 959px) {
  .v-application img,
  body img {
    max-width: 44% !important;
    display: block !important;
  }
  .imageWidth {
    right: -10% !important;
  }
  .v-application img[data-v-6d235ec8],
  body img[data-v-6d235ec8] {
    max-width: 120%;
    display: block;
  }
}
</style>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 16px;
  height: 25px;
}

.theme--light.v-data-table {
  font-family: "PFBagueSansPro-Light";
  line-height: 1.5;
}

th {
  font-size: 16px !important;
  font-family: "PFBagueSansPro-Bold";
}

.v-application p,
body p {
  line-height: 1.5;
  font-size: 16px;
}

/* @media only screen and (min-width: 960px) {
  .lalala {
    margin-top: -30px;
  }
} */
@media only screen and (min-width: 960px) and (max-width: 1024px) {
  .lalala {
    margin-top: -60px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1263px) {
  .lalala {
    margin-top: -30px;
  }
}
@media only screen and (min-width: 1264px) and (max-width: 1374px) {
  .lalala {
    margin-top: -60px;
  }
}
@media only screen and (min-width: 1375px) {
  .lalala {
    margin-top: -30px;
  }
}
.container {
  width: 90%;
  padding: 0 15px;
  padding-top: 0px;
  padding-right: 15px;
  padding-bottom: 0px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
p.regularFamily {
  font-family: "PFBagueSansPro-Light" !important;
}
</style>
